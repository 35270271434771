<template>
  <v-app class="app-container fill-height">
    <v-app-bar class="shadow" app color="white" dense fixed clipped-left>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon
          class="rounded app-container-menu-btn"
          medium
          @click="toggle"
        />
      </div>
      <v-spacer />
    </v-app-bar>
    <v-navigation-drawer v-model="status" width="230" app clipped>
      <v-list expand nav dense>
        <div v-if="menus.length">
          <template v-for="menu in menus">
            <v-list-item
              v-if="!menu.children && menu.meta.show"
              :key="menu.name"
              link
              :to="{ name: menu.name }"
            >
              <v-list-item-icon>
                <v-icon color="primary">{{ menu.meta.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menu.meta.label }}</v-list-item-title>
            </v-list-item>
            <v-list-group
              v-else-if="menu.children && menu.meta.show"
              v-model="menu.active"
              :key="menu.name"
              :prepend-icon="menu.meta.icon"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ menu.meta.label }}</v-list-item-title>
              </template>
              <template v-for="submenu in menu.children">
                <v-list-item
                  v-if="submenu.meta.show"
                  :key="submenu.name"
                  link
                  :to="{ name: submenu.name }"
                >
                  <v-list-item-icon>
                    <v-icon>{{ submenu.meta.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    submenu.meta.label
                  }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Index",

  data() {
    return {
      status: true,
      menus: [],
      selected: "dashboard"
    };
  },

  created() {
    this.setMenus().then(res => {
      this.setCurrentMenu(res);
    });
  },

  methods: {
    toggle() {
      this.status = !this.status;
      console.log(this.status);
    },

    async setMenus() {
      const routes = this.$router.options.routes;
      const navMenus = routes.filter(item => {
        return item.name === "index";
      });

      this.menus = navMenus[0].children;

      return this.menus;
    },

    setCurrentMenu(menus) {
      const currentRouteName = this.$router.currentRoute.name;

      menus.map(menu => {
        if (menu.name === currentRouteName) {
          menu.active = true;
        }
      });
      this.selected = currentRouteName;
    }
  }
};
</script>

<style lang="scss">
.app-container {
  &-menu-btn {
    width: 40px !important;
    height: 40px !important;
  }
}
</style>
